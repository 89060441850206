<template>
  <div class="extration-bdd-frais">
    <b-button
      size="sm"
      class="button-export-style  ml-2"
      v-b-modal:mailConfirmation
      title="Envoyez un email aux installateurs sélectionnés"
    >
      <font-awesome-icon icon="envelope"
    /></b-button>
    <b-modal
      ref="mailConfirmation"
      id="mailConfirmation"
      no-close-on-backdrop
      :hide-footer="true"
      title="confiramtion d'envoie"
      modal-class="modal-extaction-bdd-frais"
    >
      Vous voulez envoyer les emails?
      <div class="form-modal-custom-style mt-2">
        <div class="actionModel">
          <b-button class="button-valide-style " @click="handleSendMail">
            <span>
              Envoyer
            </span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    dataToUse: { required: true }
  },
  data() {
    return {
      error: null
    };
  },
  methods: {
    ...mapActions(['sendMailComptaRh']),
    async handleSendMail() {
      var bodyFormData = new FormData();
      let dataToUse = [];
      dataToUse = this.dataToUse.filter(row => row.check == true);
      if (dataToUse.length == 0) {
        dataToUse = this.dataToUse;
      }
      let indexToUse = 0;
      for (let j = 0; j < dataToUse.length; j++) {
        bodyFormData.append('ids[' + [indexToUse] + ']', dataToUse[j].id);
        indexToUse = indexToUse + 1;
      }
      const response = await this.sendMailComptaRh(bodyFormData);
      if (response.succes) {
        if (this.getErrorOneDayComptaRh?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'error',
            title: this.getErrorOneDayComptaRh?.error,
            clickable: true,
            time: 0,
            message: this.getErrorOneDayComptaRh?.facture
          });
        }

        if (this.getSuccessSendMailsComptaRh?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'success',
            title: this.getSuccessSendMailsComptaRh?.success,
            clickable: true,
            time: 0,
            message: this.getSuccessSendMailsComptaRh?.facture
          });
        }
        if (this.getErrorInvalidElmailComptaRh?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'error',
            title: this.getErrorInvalidElmailComptaRh?.error,
            clickable: true,
            time: 0,
            message: this.getErrorInvalidElmailComptaRh?.facture
          });
        }
        if (this.getErrorHasntEmailComptaRh?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'error',
            title: this.getErrorHasntEmailComptaRh?.error,
            clickable: true,
            time: 0,
            message: this.getErrorHasntEmailComptaRh?.facture
          });
        }
        if (this.getErrorHasentTemplateComptaRh?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'error',
            title: this.getErrorHasentTemplateComptaRh?.error,
            clickable: true,
            time: 0,
            message: this.getErrorHasentTemplateComptaRh?.facture
          });
        }
        if (this.getErrorHasentTemplateEmailComptaRh?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'error',
            title: this.getErrorHasentTemplateEmailComptaRh?.error,
            clickable: true,
            time: 0,
            message: this.getErrorHasentTemplateEmailComptaRh?.facture
          });
        }
        if (this.getErrorNotValidYet?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'error',
            title: this.getErrorNotValidYet?.error,
            clickable: true,
            time: 0,
            message: this.getErrorNotValidYet?.facture?.length + ' Factures'
          });
        }
      } else {
        this.flashMessage.show({
          status: 'error',
          title: 'ERROR',
          clickable: true,
          time: 0,
          message: this.getOtherErrorMail
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      'getErrorHasntEmailComptaRh',
      'getErrorInvalidElmailComptaRh',
      'getSuccessSendMailsComptaRh',
      'getErrorOneDayComptaRh',
      'getOtherErrorMail',
      'getErrorHasentTemplateComptaRh',
      'getErrorHasentTemplateEmailComptaRh',
      'getErrorNotValidYet'
    ])
  },
  mounted() {}
};
</script>

<style scoped lang="scss"></style>
<style lang="scss">
.align-item-extract-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-extaction-bdd-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
</style>
